
import {removeElement} from "@/plugins/tvModal/utils";
import ModalBase from "@/layouts/shop/components/tvmodalPlugin/ModalBase";
import ShopButton from "@/layouts/shop/components/buttons/ShopButton";
import ShopModal from "@/layouts/shop/components/modal/ShopModal.vue";

export default {
  name: 'GlobalModal',
  components: {ShopModal, ShopButton, ModalBase},
  props: {
    type: {
      type: String,
      required: false,
      default: 'info',
      validate: (type) => {
        return ['success', 'info', 'danger', 'warning'].includes(type)
      },
    },
    title: {
      type: [Boolean, String],
      required: false,
      default: false,
    },
    body: {
      type: String,
      required: true,
    },
    primary: {
      type: [Boolean, Object],
      required: false,
      default: false,
    },
    secondary: {
      type: [Boolean, Object],
      required: false,
      default: false,
    },
    // if set to false, user needs to handle close manually
    // passed as callback to the actions
    closeAfterAction: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      active: false,
      typeColors: {
        success: 'bg-green-100 dark:bg-green-900',
        info: 'bg-blue-100 dark:bg-blue-900',
        danger: 'bg-red-100 dark:bg-red-900',
        warning: 'bg-yellow-100 dark:bg-yellow-900',
      },
      renderPrimary: true
    }
  },
  async mounted() {
    this.active = true
    setTimeout(() => {
      if (this.primary && this.$refs.primary)
        this.$refs.primary.$el.firstChild.focus()
    }, 200)
  },
  methods: {
    async focus(index = 0) {
      document.getElementById(`modal-button-${index}`).focus()
    },
    async action(type) {

      if (type === 'primary') {
        this.primary.action({close: this.$refs.ModalBase.closeMe, setLoading: this.setLoading});
      }
      if (type === 'secondary') {
        this.secondary.action(this.$refs.ModalBase.closeMe);
      }

      if(this.closeAfterAction) {
        this.$refs.ModalBase.closeMe();
      }

    },
    setLoading(bool) {
      this.primary.loading = bool;
    },
    async destroy() {
      if (process.client && window.TMR_DIALOG_SHOWN) {
        window.TMR_DIALOG_SHOWN = false;
      }

      this.active = false
      this.$destroy();
      removeElement(this.$el);
    },
  },
  computed: {
    getPrimary() {
      if(this.primary !== null && typeof this.primary === "object") {
        return {
          ...this.primary,
          loading: false
        }
      }

      return this.primary;
    }
  },
}


import {mapGetters, mapMutations} from "vuex";
import PaginationButton from "@/layouts/shop/pagination/PaginationButton";

export default {
  name: "PaginationBar",
  components: {PaginationButton},
  data() {
    return {
      isLoading: false
    }
  },
  methods: {
    ...mapMutations('pagination', ["setCurrentPage", "resetPagination"]),
    async setPage(page) {
      if (this.getCurrentPage === page)
        return false;

      this.setCurrentPage(page);

      await new Promise((resolve, reject) => this.$router.push({
        query: {...this.$route.query, page: page},
      }, resolve, reject));
    },
    goToPreviousPage() {
      if (this.getCurrentPage === 1)
        return false;

      this.setPage(this.getCurrentPage - 1);
    },
    goToNextPage() {
      if (this.getCurrentPage === this.getLastPage)
        return false;

      this.setPage(this.getCurrentPage + 1);
    },
    reFetch() {
      this.isLoading = true;
      this.$nuxt.refresh().then(() => {
        this.$nextTick(() => {
          this.isLoading = false;
        })
      });
    }
  },
  watch: {
    isLoading: function (bool) {
      this.$emit('loading', bool);
    },
    // Listen for page change
    '$route.query.page': function (page) {
      this.$nextTick(() => {
        this.$emit("change", page);
        this.reFetch();
      });
    }
  },
  computed: {
    getPagesToDisplay() {
      let maxPages = 5;
      let totalPages = this.getLastPage;
      let startPage, endPage;
      const currentPage = this.getCurrentPage;

      if (totalPages <= maxPages) {
        // total pages less than max so show all pages
        startPage = 1;
        endPage = totalPages;
      } else {
        // total pages more than max so calculate start and end pages
        let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
        let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;

        if (currentPage <= maxPagesBeforeCurrentPage) {
          // current page near the start
          startPage = 1;
          endPage = maxPages;
        } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
          // current page near the end
          startPage = totalPages - maxPages + 1;
          endPage = totalPages;
        } else {
          // current page somewhere in the middle
          startPage = currentPage - maxPagesBeforeCurrentPage;
          endPage = currentPage + maxPagesAfterCurrentPage;
        }
      }

      return Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);
    },
    showFirstDivider() {
      return this.getPagesToDisplay.length && this.getPagesToDisplay[0] !== 1;
    },
    showSecondDivider() {
      return this.getPagesToDisplay.length && this.getPagesToDisplay[this.getPagesToDisplay.length - 1] !== this.getLastPage;
    },
    ...mapGetters('pagination', [
      'getLastPage',
      'getCurrentPage',
    ]),
  },
}

export const state = () => ({
  items: {
    total: 0,
    perPage: 12,
    from: 1,
    to: 12
  },
  currentPage: 1,
  lastPage: 1,
})

export const mutations = {
  setFromResponse(state, {total, perPage, currentPage, lastPage, from, to}) {
    state.items.total = total;
    state.items.perPage = perPage;
    state.items.from = from;
    state.items.to = to;
    state.currentPage = currentPage;
    state.lastPage = lastPage;
  },
  setCurrentPage(state, page) {
    state.currentPage = page;
  },
  resetPagination(state) {
    state.items.total = 0;
    state.items.perPage = 12;
    state.items.from = 1;
    state.items.to = 12;
    state.currentPage = 1;
    state.lastPage = 1;
  },
}

export const getters = {
  getLastPage: state => state.lastPage,
  getCurrentPage: state => state.currentPage,
  hasPages: state => state.lastPage !== 1,
  getTotalItemCount: state => state.items.total,
}

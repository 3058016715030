
export default {
    name: "ShopPageTitle",
    props: {
        title: {
            type: String,
            default: ""
        },
        subtitle: {
            type: String,
            default: null
        },
        size: {
            type: String,
            validator: val => ['normal', 'small', 'big'].includes(val),
            default: 'normal'
        },
        withMargin: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        getTitle() {
            if(this.title.length)
                return this.title;

            return this.$slots.default[0].text;
        },
        getClasses() {
            switch (this.size) {
                case "big":
                    return this.withMargin ? 'text-5xl mb-14' : 'text-5xl';
                case "normal":
                    return this.withMargin ? 'text-3xl mb-6' : 'text-3xl';
                case "small":
                    return this.withMargin ? 'text-xl mb-6' : 'text-xl';
            }
        }
    },
}



import ShopModal from "@/layouts/shop/components/modal/ShopModal.vue";
import ShopButton from "@/layouts/shop/components/buttons/ShopButton.vue";

export default {
  name: "AccountModifyOrderButton",
  components: {ShopButton, ShopModal},
  props: {
    orderId: {
      type: Number,
      required: null
    },
    paymentProvider: {
      type: String,
    }
  },
  data: () => ({
    showModal: false,
    isLoading: false,

    body: '',
  }),
  created() {
    let body = "<div class='p-2'>"

    body += `<div class='mb-3'>${this.$t('confirm_text_first_line')}</div>`;

    body += "<ul class='list-decimal ml-6 list-outside font-medium mt-1''>";
    this.$t(this.getLineKey).forEach(line => {
      if (Array.isArray(line)) {
        let text = line[0];
        let subLines = line[1];

        body += `<li>${text} <ul class="mb-3 list-disc list-outside pl-4 pt-2 font-normal">`;
        subLines.forEach(sl => {
          body += `<li>${sl}</li>`;
        });
        body += "</ul></li>";
      } else {
        body += `<li>${line}</li>`
      }
    })
    body += "</ul>";

    // Timeslot notice
    body += `<div class='mt-2 text-xs text-red-600'>${this.$t('timeslot_info')}</div></div>`;

    body += "</p>";


    this.body = body;
  },
  methods: {
    openModifyOrderModal() {
      this.showModal = true;
    },
    closeModifyOrderModal() {
      this.showModal = false;
    },
    cancelOrder() {
      this.isLoading = true;

      this
        .$orderRepository
        .cancelOrder(this.orderId)
        .then(() => {
          this.$nextTick(async () => {
            await this.$auth.fetchUser();
            this.$toast.success(this.$t('account.orders.modify.confirmation_text'));
            await this.$router.push(
              this.localePath({
                name: 'index'
              })
            );
          });
        }).finally(() => {
        this.isLoading = false;
      });
    }
  },
  computed: {
    getLineKey() {
      if (this.paymentProvider === "saferpay") {
        return 'saferPayLines';
      }
      if (this.paymentProvider === "payconiq") {
        return 'payconiqLines';
      }
      return "otherPaymentLines";
    }
  },
  i18n: {
    messages: {
      fr: {
        confirm_title: "Comment modifier ma commande ?",
        confirm_button: "Annuler commande",
        cancel_button: "Garder commande",
        confirm_text_first_line: "Pour “modifier” cette commande, il suffit de suivre ces étapes:",
        timeslot_info: "*Attention ! Nous ne pouvons pas garantir que le créneau de livraison initialement choisi pour cette commande sera toujours disponible",
        saferPayLines() {
          return [
            [
              'Annulez cette commande',
              [
                'tous les produits de la commande sont <span class=\'underline\'>ajoutés</span> à votre panier actuel',
                'votre payment par carte de crédit est annulé',
                'la commande est annulée',
              ],
            ],
            'Modifiez votre panier selon vos besoins',
            'Passez à nouveau en caisse et effectuez un nouveau payement*',
          ]
        },
        payconiqLines() {
          return [
            [
              'Annulez cette commande',
              [
                'tous les produits de la commande sont <span class=\'underline\'>ajoutés</span> à votre panier actuel',
                'le montant de votre payment Payconiq est crédité sur votre “Luxcaddy Wallet”',
                'la commande est annulée',
              ],
            ],
            'Modifiez votre panier selon vos besoins',
            'Passez à nouveau en caisse et payez uniquement la différence*',
          ]
        },
        otherPaymentLines() {
          return [
            [
              'Annulez cette commande',
              [
                'tous les produits de la commande sont <span class=\'underline\'>ajoutés</span> à votre panier actuel',
                'la commande est annulée',
              ],
            ],
            'Modifiez votre panier selon vos besoins',
            'Passez à nouveau en caisse',
          ]
        },
      },
      de: {
        confirm_title: "Wie bearbeite ich meine Bestellung?",
        confirm_button: "Bestellung stornieren",
        cancel_button: "Bestellung behalten",
        confirm_text_first_line: "Um diese Bestellung zu “bearbeiten” reicht es folgende Schritte zu befolgen:",
        timeslot_info: "*Achtung! Wir können nicht garantieren, dass das ursprünglich für diese Bestellung gewählte Lieferzeitfenster noch verfügbar ist.",
        saferPayLines() {
          return [
            [
              'Diese Bestellung stornieren',
              [
                'alle Produkte der Bestellung werden <span class=\'underline\'>zu Ihrem aktuellen Warenkorb hinzugefügt</span>.',
                'Ihre Kreditkartenzahlung wird storniert',
                'die Bestellung wird storniert',
              ],
            ],
            'Ändern Sie Ihren Warenkorb nach Bedarf',
            'Gehen Sie erneut zur Kasse und führen Sie eine erneute Zahlung durch*',
          ]
        },
        payconiqLines() {
          return [
            [
              'Diese Bestellung stornieren',
              [
                'alle Produkte der Bestellung werden <span class=\'underline\'>zu Ihrem aktuellen Warenkorb hinzugefügt</span>.',
                'der Betrag Ihrer Payconiq-Zahlung wird auf Ihrem "Luxcaddy Wallet" gutgeschrieben',
                'die Bestellung wird storniert',
              ],
            ],
            'Ändern Sie Ihren Warenkorb nach Bedarf',
            'Gehen Sie erneut zur Kasse und zahlen Sie nur die Differenz*.',
          ]
        },
        otherPaymentLines() {
          return [
            [
              'Diese Bestellung stornieren',
              [
                'alle Produkte der Bestellung werden <span class=\'underline\'>zu Ihrem aktuellen Warenkorb hinzugefügt</span>.',
                'die Bestellung wird storniert',
              ],
            ],
            'Ändern Sie Ihren Warenkorb nach Bedarf',
            'Gehen Sie erneut zur Kasse*',
          ]
        },
      },
      en: {
        confirm_title: "How do I modify my order?",
        confirm_button: "Cancel order",
        cancel_button: "Keep order",
        confirm_text_first_line: 'To "edit" this order, it\'s enough to follow these steps:',
        timeslot_info: "*Attention. We cannot guarantee that the delivery window originally selected for this order is still available.",
        saferPayLines() {
          return [
            [
              'Cancel this order',
              [
                'all products of the order will be <span class=\'underline\'>added</span> to your current shopping cart.',
                'Your credit card payment is cancelled',
                'the order is cancelled',
              ],
            ],
            'Change your shopping cart as needed',
            'Go to the checkout again and make a new payment*.',
          ]
        },
        payconiqLines() {
          return [
            [
              'Cancel this order',
              [
                'all products of the order will be <span class=\'underline\'>added</span> to your current shopping cart.',
                'the amount of your Payconiq payment will be credited to your "Luxcaddy Wallet"',
                'the order is cancelled',
              ],
            ],
            'Change your shopping cart as needed',
            'Go to the checkout again and only pay the difference*.',
          ]
        },
        otherPaymentLines() {
          return [
            [
              'Cancel this order',
              [
                'all products of the order will be <span class=\'underline\'>added</span> to your current shopping cart.',
                'the order is cancelled',
              ],
            ],
            'Change your shopping cart as needed',
            'Go to the checkout again*',
          ]
        },
      },
      lb: {
        confirm_title: "Wei beaarbechten ech meng commande?",
        confirm_button: "Commande stornéieren",
        cancel_button: "Commande behaalen",
        confirm_text_first_line: 'Fir des commande ze "beaarbechten" geet et duer folgend Schrëtt duerchzefeieren:',
        timeslot_info: "*Opgepasst. Mir kennen net garantéieren dass den Liwwerzäitraum deen dir fir des commande initial gewielt hutt nach verfügbar ass.",
        saferPayLines() {
          return [
            [
              'Des commande stornéieren',
              [
                'All Produiten aus dëser commande gi nees an ären Weenchen <span class=\'underline\'>dobäigesat</span>.',
                'Är Kreditkaarten-Payement gëtt stornéiert',
                'D\'commande gëtt stornéiert',
              ],
            ],
            'Passt ären Weenchen no ären Wënsch un',
            'Gitt nees an d\'Kees an maacht en neien Payement*.',
          ]
        },
        payconiqLines() {
          return [
            [
              'Des commande stornéieren',
              [
                'All Produiten aus dëser commande gi nees an ären Weenchen <span class=\'underline\'>dobäigesat</span>.',
                'De Montant vun ärem Payconiq-Payement gëtt op är "Luxcaddy Wallet" kreditéiert.',
                'D\'commande gëtt stornéiert',
              ],
            ],
            'Passt ären Weenchen no ären Wënsch un',
            'Gitt nees an d\'Kees an bezuelt nëmmen d\'Differenz.*.',
          ]
        },
        otherPaymentLines() {
          return [
            [
              'Des commande stornéieren',
              [
                'All Produiten aus dëser commande gi nees an ären Weenchen <span class=\'underline\'>dobäigesat</span>.',
                'D\'commande gëtt stornéiert',
              ],
            ],
            'Passt ären Weenchen no ären Wënsch un',
            'Gitt nees an d\'Kees*',
          ]
        },
      }
    }
  }
}


export default {
  name: "ShopNavButtonMenuLink",
  props: {
    to: {
      type: [Object, String],
      default: null
    },
    icon: {
      type: String
    },
    label: {
      type: String
    },
    disabled: {
      type: Boolean
    },
    loading: {
      type: Boolean
    }
  },
  methods: {
    emitClick(e) {
      this.$emit('click', e);
    }
  },
  computed: {
    getTextClasses() {
      let classes = "block px-1.5 lg:px-4 py-1.5 w-full tracking-tight leading-tight lg:tracking-normal rounded-lg flex items-center space-x-6 text-sm 4xl:text-md";

      if(this.disabled) {
        return classes += " opacity-50 cursor-disabled select-none";
      }

      return classes += " hover:bg-gray-100 dark:hover:bg-gray-600 hover:text-luxcaddy-600 dark:hover:text-luxcaddy-400 focus:outline-none focus:ring-2 focus:ring-luxcaddy-600 focus:text-luxcaddy-600 cursor-pointer";
    },
    isRouterLink() {
      return this.to !== null;
    }
  },
}


import LogoSvg from "./shop/svgs/LogoSvg";
import ShopMobileCartBar from "./shop/mobile/cart/ShopMobileCartBar";
import BreakpointDebug from './shop/debug/BreakpointDebug'
import ShopThemeToggle from "./shop/components/theme/ShopThemeToggle";
import LoadingSpinner from "@/layouts/shop/components/loading/LoadingSpinner.vue";
import ShopMobileBackToTopButton from "@/layouts/shop/components/ShopMobileBackToTopButton.vue";

export default {
  name: "simple",
  components: {ShopMobileBackToTopButton, LoadingSpinner, ShopThemeToggle, BreakpointDebug, ShopMobileCartBar, LogoSvg },
}

const url = "/newsletter";

export default $api => ({
  async subscribe(email, captchaToken) {
    return await $api.$post(`${url}/subscribe`,  {
      email: email,
      captchaToken: captchaToken
    });
  },

  async validateSubscription(token) {
    return await $api.$get(`${url}/validate?token=` + token);
  },
});

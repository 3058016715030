
import OrderListItemStatus from "./OrderListItemStatus";
import ShopButton from "../../../buttons/ShopButton";
import ShopCartActionButton from "../../../cart/includes/ShopCartActionButton";
import DropDownMenu from "../../../dropdown/DropDownMenu";
import AccountCard from "@/layouts/shop/components/account/sub/AccountCard/AccountCard";
import AccountCardTitle from "@/layouts/shop/components/account/sub/AccountCard/AccountCardTitle";
import AccountCardSubText from "@/layouts/shop/components/account/sub/AccountCard/AccountCardSubText";
import AccountOrderNotPaidAlert from "@/layouts/shop/components/account/orders/sub/AccountOrderNotPaidAlert";
import AccountModifyOrderButton
  from "@/layouts/shop/components/account/orders/sub/ModifyOrder/AccountModifyOrderModal.vue";
import Popper from 'vue-popperjs';
import ShopNavMenuLink from "@/layouts/shop/partials/nav/ShopNavMenuLink.vue";
import ShopNavButtonMenuLink from "@/layouts/shop/partials/nav/ShopNavButtonMenuLink.vue";

export default {
  name: "OrderListItem",
  components: {
    ShopNavButtonMenuLink, ShopNavMenuLink,
    AccountModifyOrderButton,
    AccountOrderNotPaidAlert,
    AccountCardSubText,
    AccountCardTitle,
    AccountCard,
    DropDownMenu,
    ShopCartActionButton,
    ShopButton,
    OrderListItemStatus,
    Popper
  },
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    openDeliveryTrackingLoading: false,
  }),
  methods: {
    openModifyOrderModal() {
      if(!this.canCancelOrder) {
        return false;
      }
      if(this.$refs.modifyOrderModal) {
        this.$refs.modifyOrderModal.openModifyOrderModal();
      }

      this.$refs.popper.doClose();
    },
    navigateToOrderOverview() {
      this.$router.push(this.localePath({
        name: 'account-index-orders-id-overview',
        params: { id: this.order.id }
      }));
      this.$refs.popper.doClose();
    },
    navigateToChangeTimeslot() {
      if(!this.canModifyTimeslot) {
        return false;
      }

      this.$router.push(this.localePath({
        name: 'account-index-orders-id-modify-timeslot',
        params: { id: this.order.id }
      }));
      this.$refs.popper.doClose();
    },
    openDeliveryTracking() {
      this.openDeliveryTrackingLoading = true;
      this.$router.push(
        this.localePath({
          name: 'account-index-orders-id-delivery-deliveryId-tracking',
          params: {
            id: this.order.id,
            deliveryId: this.order.deliveryId,
          }
        })
      );
    }
  },
  computed: {
    getDeliveryDate() {
      const timeslot = this.order.timeSlot;

      return timeslot === null
        ? null
        : `${this.$dayjs(timeslot.startDate).format('DD.MM.YYYY HH:mm')} - ${this.$dayjs(timeslot.endDate).format('HH:mm')}`;
    },
    hasDelivery() {
      return this.order.deliveryId !== null;
    },
    getTotalAmount() {
      return this.$moneyHelper.centsToEuroString(this.order.total.amount);
    },
    getStatus() {
      if (this.order.status === 'confirmed' && this.isInSupplierOrder) {
        return 'inFulfilment'
      }

      return this.order.status
    },
    isDelivered() {
      return this.order.status === 'delivered';
    },
    isEnRoute() {
      return this.order.status === 'enRoute';
    },
    isFulfilmentFinalized() {
      return this.order.fulfilmentStatus === 'fulfilmentFinalized';
    },
    isDeliveryTrackingAvailable() {
      return this.hasDelivery && [
          'fulfilmentFinalized',
          'enRoute',
      ].includes(this.order.status);
    },
    canCancel() {
      return this.order.canCancel;
    },
    isInSupplierOrder() {
      return this.order.isInSupplierOrder;
    },
    canCancelOrder() {
      return this.order.canCancel && !this.isInSupplierOrder;
    },
    canModifyTimeslot() {
      return this.order.canModifyTimeslot;
    }
  }
}

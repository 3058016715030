
import {isMobileDevice} from "@/helpers/mobileHelper.js";

export default {
  name: "ShopMobileBackToTopButton",
  data() {
    return {
      windowTop: 0
    }
  },
  methods: {
    scrollToTop() {
      if(process.client && window) {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        })
      }
    },
    onScroll(e) {
      this.windowTop = window.top.scrollY;
    }
  },
  computed: {
    shouldDisplay() {
      if(process.server || !isMobileDevice()) {
        return false;
      }

      return this.windowTop > 200;
    }
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll)
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll)
  },
}

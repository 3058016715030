
import ShopSidebar from './shop/ShopSidebar'
import ShopFooter from './shop/ShopFooter'
import ShopTopNav from "./shop/ShopTopNav";
import ShopMobileTopNav from "./shop/mobile/ShopMobileTopNav";
import ShopRightBar from "./shop/partials/rightBar/ShopRightBar";
import ShopMobileCartBar from "./shop/mobile/cart/ShopMobileCartBar";
import BreakpointDebug from "./shop/debug/BreakpointDebug";
import ShoppingListModal from "@/layouts/shop/components/buttons/product/shoppinglist/modal/ShoppingListModal";
import ShopOpenOrderBanner from "@/layouts/shop/components/orders/ShopOpenOrderBanner";
import ShopOrderDeliveryTrackingBanners from "@/layouts/shop/components/orders/ShopOrderDeliveryTrackingBanners";
import CookieConsent from "@/layouts/shop/CookieConsent.vue";
import MobileCategoryMenuIndicator from "@/layouts/shop/components/indicators/MobileCategoryMenuIndicator.vue";
import eventMixin from "@/mixins/eventMixin.js";
import ChristmasNotificationBanners from "@/layouts/shop/components/notifications/ChristmasNotificationBanner.vue";
import ShopMobileBackToTopButton from "@/layouts/shop/components/ShopMobileBackToTopButton.vue";

export default {
  name: 'ShopLayout',
  components: {
    ShopMobileBackToTopButton,
    ChristmasNotificationBanners,
    MobileCategoryMenuIndicator,
    CookieConsent,
    ShopOpenOrderBanner,
    ShopOrderDeliveryTrackingBanners,
    ShoppingListModal,
    BreakpointDebug,
    ShopMobileCartBar,
    ShopRightBar,
    ShopMobileTopNav,
    ShopTopNav,
    ShopFooter,
    ShopSidebar
  },
  mixins: [eventMixin],
  watch: {
    '$i18n.locale': function () {
      // Reload categories when language changed
      this.$fetch();
    },
    '$route': function (to) {
      // Reload page if refresh needed.
      if(process.client && this.$store.getters['app/isAppRefreshNeeded']) {
        window.location.assign(to);
      }
    }
  },
  async fetch() {
    // FETCH Sidebar categories
    await this.$store.dispatch('sidebar/fetchCategories');
  },
  methods: {
    getGaClientId() {
      const gaCookie = document.cookie.split('; ').find((cookie) => cookie.startsWith('_ga='));
      if (gaCookie) {
        // Extract the GA client ID from the cookie value
        return  gaCookie.split('.').slice(-2).join('.');
      }

      return null;
    },
    getGaSessionId() {
      const gaSessionCookie = document.cookie.split('; ').find((cookie) => cookie.startsWith('_ga_'));
      if (gaSessionCookie) {

        // Extract the GA client ID from the cookie value
        return gaSessionCookie.split('.').slice(2)[0] ?? null;
      }

      return null;
    }
  },
  created() {
    this.$store.commit('checkout/clearStore');

    if (!this.$auth.loggedIn) {
      this.$store.commit('guestCart/initCartQuantities');
    }

    this.$store.dispatch('filters/fetchAvailableFilters');
  },
  mounted() {
    this.$store.commit('app/setGaClientId', this.getGaClientId());
    this.$store.commit('app/setGaSessionId', this.getGaSessionId());
    if (this.$auth.loggedIn) {
      this.$store.dispatch('shoppingLists/fetchCompactLists');
    }
  }
}

export default $api => ({
  async loginByLink(id, email, token) {
    return await $api.$post(`/auth/auth-link/verify`, {
      id, email, token
    });
  },

  async requestLoginLinkEmail(email) {
    return await $api.$post(`/auth/auth-link/request`, {
      email
    });
  },
});

import ProductRepository from "../repositories/ProductRepository";
import CategoryRepository from '../repositories/CategoryRepository'
import CartRepository from "../repositories/CartRepository";
import TagRepository from "../repositories/TagRepository";
import OrderRepository from "../repositories/OrderRepository";
import DeliveryContactRepository from "../repositories/DeliveryContactRepository";
import CountryRepository from "../repositories/CountryRepository";
import NewsletterRepository from "../repositories/NewsletterRepository";
import AccountAndProfileRepository from "../repositories/AccountAndProfileRepository";
import PaymentRepository from "~/repositories/PaymentRepository";
import DeliveryTimeslotRepository from "~/repositories/DeliveryTimeslotRepository";
import BlogRepository from "@/repositories/BlogRepository";
import PageDataRepository from "@/repositories/PageDataRepository";
import RegisterRepository from "@/repositories/RegisterRepository";
import ShoppingListRepository from "@/repositories/ShoppingListRepository";
import DeliveryRepository from "@/repositories/DeliveryRepository";
import SupportTicketRepository from "@/repositories/SupportTicketRepository";
import ForgotPasswordRepository from "../repositories/ForgotPasswordRepository";
import BrandRepository from "@/repositories/BrandRepository";
import InvoiceRepository from "@/repositories/InvoiceRepository.js";
import ViesVatNumberRepository from "@/repositories/ViesVatNumberRepository.js";
import LoginByLinkRepository from "@/repositories/LoginByLinkRepository.js";

export default ({$api}, inject) => {
  inject('productRepository', ProductRepository($api));
  inject('brandRepository', BrandRepository($api));
  inject('categoryRepository', CategoryRepository($api));
  inject('orderRepository', OrderRepository($api));
  inject('invoiceRepository', InvoiceRepository($api));
  inject('cartRepository', CartRepository($api));
  inject('registerRepository', RegisterRepository($api));
  inject('tagRepository', TagRepository($api));
  inject('deliveryContactRepository', DeliveryContactRepository($api));
  inject('deliveryTimeslotRepository', DeliveryTimeslotRepository($api));
  inject('countryRepository', CountryRepository($api));
  inject('newsletterRepository', NewsletterRepository($api));
  inject('accountAndProfileRepository', AccountAndProfileRepository($api));
  inject('paymentRepository', PaymentRepository($api));
  inject('blogRepository', BlogRepository($api));
  inject('pageDataRepository', PageDataRepository($api));
  inject('shoppingListRepository', ShoppingListRepository($api));
  inject('deliveryRepository', DeliveryRepository($api));
  inject('supportTicketRepository', SupportTicketRepository($api));
  inject('forgotPasswordRepository', ForgotPasswordRepository($api));
  inject('viesVatNumberRepository', ViesVatNumberRepository($api));
  inject('loginByLinkRepository', LoginByLinkRepository($api));
}


export default {
  name: "DividerWithText",
  props: {
    text: {
      type: String,
      required: true
    }
  }
}
